import { FC, useState, useEffect, useCallback } from 'react';
import { ModalContext } from '../../../contexts/ModalContext';
import { useRecoilValue } from 'recoil';
import { ModuleType } from '../../../models/Module';
import { currentClientAtom } from '../../../recoil/atoms/Clients';
import ClientTemplateModuleService from '../../../services/ClientTemplateModuleService';
import ModuleService from '../../../services/ModuleService';
import { DocumentReferenceAnswer } from '../../form/action-types/document-reference/DocumentReferenceAction';
import { ClientModuleTemplateDefault } from '../../../models/ClientModuleDefaults';
import CreateDocumentWizard from '../create-document/CreateDocumentWizard';
import ClientService from '../../../services/ClientService';
import User from '../../../models/User';

type ReferenceDocumentModalProps = {
  open: boolean;
  selectedItems?: DocumentReferenceAnswer[];
  disabledItems?: DocumentReferenceAnswer[];
  onClose: () => void;
  onComplete: (selectedItems: DocumentReferenceAnswer[]) => void;
};

const ReferenceDocumentModal: FC<ReferenceDocumentModalProps> = (props) => {
  const { open, selectedItems, onClose, onComplete, disabledItems } = props;

  const currentClient = useRecoilValue(currentClientAtom);
  const [clientModuleTemplateDefaults, setClientModuleTemplateDefaults] = useState<ClientModuleTemplateDefault[]>([]);
  const [clientUsers, setClientUsers] = useState<User[]>([]);

  const getUsers = useCallback(() => {
    if (currentClient && open) {
      ClientService.getUsers().then((res) => {
        setClientUsers(res);
      });
    }
  }, [currentClient, open]);

  useEffect(() => {
    getUsers();
  }, [currentClient, getUsers, open]);

  const getClasses = useCallback(() => {
    if (currentClient) {
      const clientTemplateModuleService = new ClientTemplateModuleService(currentClient?.id);
      clientTemplateModuleService.getTemplatesConfigurations(ModuleType.Document).then((templateConfigs) => {
        ModuleService.getModules().then((res) => {
          res.data.forEach((clientModule) => {
            const moduleTemplate = templateConfigs.data.find((x) => x.templateModule.id === clientModule.templateId);
            if (moduleTemplate) {
              moduleTemplate.clientModuleId = clientModule.id;
            }
          });
          setClientModuleTemplateDefaults(templateConfigs.data.sort((a, b) => a.templateModule.sortOrder - b.templateModule.sortOrder));
        });
      });
    }
  }, [currentClient]);

  useEffect(() => {
    getClasses();
  }, [getClasses]);

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [open]);

  const onDocumentCreated = useCallback(
    (id: string, classId: string, nextDocNumber: number) => {
      setClientModuleTemplateDefaults((prevDefaults) => {
        return prevDefaults.map((template) => {
          if (template.clientModuleId === classId) {
            return {
              ...template,
              nextDocumentNumber: nextDocNumber,
            };
          }
          return template;
        });
      });
      onComplete([...(selectedItems ?? []), { id: id }]);
      onClose();
    },
    [onClose, onComplete, selectedItems],
  );

  return (
    <>
      <ModalContext.Provider
        value={{
          open: open,
          onClose: onClose,
          modalWidth: 'xl:w-[800px] w-3/5',
        }}
      >
        {open && (
          <CreateDocumentWizard
            classes={clientModuleTemplateDefaults}
            onCancel={onClose}
            onCreated={onDocumentCreated}
            onReference={onComplete}
            classDefaults={undefined}
            activeStep={'reference'}
            clientUsers={clientUsers}
            selectedDocuments={selectedItems}
            disabledDocments={disabledItems}
            onUserInvited={getUsers}
          />
        )}
      </ModalContext.Provider>
    </>
  );
};

export default ReferenceDocumentModal;
